<template>
    <div>
        <div>
            <CustomTable
                ref="tiers_suppliers_invoices"
                id_table="tiers_suppliers_invoices"
                :items="invoicesFormatted"
                :busy.sync="table_busy"
                primaryKey="invoice_id"
                :hide_if_empty="true"
                :externSlotColumns="extern_slot_columns"
            >
                <template v-slot:custom-slot-cell(download_doc)="{ data }">
                    <a href="" v-show="data.invoice_document == true" @click.prevent="downloadInvoice(data.invoice_id)">{{ $t("action.telecharger") }}</a>
                </template>
            </CustomTable>
        </div>
    </div>
</template>


<script type="text/javascript">
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'	
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js";
    import Tiers from "@/mixins/Tiers.js"
    import Common from '@/assets/js/common.js'
    import { EventBus } from 'EventBus'


	export default {
		name: "InvoiceSupplierList",
		mixins: [Navigation, Tiers, Invoice],
		props: {
			tiers_id: Number,
		},
		data () {
			return {
                invoices: [],
                table_busy: true,
				reloadMethod: 'refreshTable',
				extern_slot_columns: [
                    'download_doc'
                ],
                events_tab: {
					'TableAction::goToModalAddSupplierInvoice': () => {
						// this.$refs.modalAddπPSupplierInvoice.show()
					},
                    'TableAction::goToModalEditSupplierInvoice': this.goToEditSupplierInvoice,
                    'TableAction::goToDeleteSupplierInvoice': this.deleteSupplierInvoice,
                }
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                if(this.tiers_id != 0) {
                    this.table_busy = true
                    this.loadInvoices()
                }
            },

            async loadInvoices() {
				this.invoices = await this.getInvoiceSuppliersList(this.tiers_id)
                this.table_busy = false
			},
			
			refreshTable() {
				this.table_busy = true
				this.loadInvoices()
			},

            async downloadInvoice(invoicesupplier_id)
            {
                await this.documentInvoiceSupplier(invoicesupplier_id)
            },

            async deleteSupplierInvoice(invoicesuppliers_id)
            {
                await this.goToDeleteSupplierInvoice(invoicesuppliers_id)
                EventBus.$emit('TableAction::stopSpin')
                this.$refs['tiers_suppliers_invoices'].refreshTable()
                this.init_component()
            }
		},
        computed: {
            invoicesFormatted(){
                let tab = []

                for (let i = 0; i < this.invoices.length; i++) {
                    let temp = this.invoices[i]
                    tab.push(temp)
                }

                return tab
            }
        },
		components: {
            CustomTable
		}
	}
</script>
